import styled from 'styled-components';
import { IconEdit } from '@salutejs/plasma-icons';
import { ChangeEvent, useRef, KeyboardEvent, FC, useEffect } from 'react';
import { Button, TextField } from '@salutejs/plasma-ui';
import { useGenerateStore } from '@/store/generate';

interface CustomTextAreaProps {
  value: string;
  disabled: boolean;
  loading: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  maxLength?: number;
}

const TextAreaWrapper = styled.div`
  position: relative;
  margin-bottom: 12px;
`;

const CustomTextArea: FC<CustomTextAreaProps> = ({
  value,
  disabled,
  onChange,
  maxLength = 140
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const focusButtonRef = useRef<HTMLButtonElement>(null);

  const { loadingImage, setEdit } = useGenerateStore((state) => ({
    loadingImage: state.loadingImage,
    setEdit: state.setEdit
  }));

  useEffect(() => {
    focusButtonRef?.current?.focus();
  }, [loadingImage]);

  useEffect(() => {
    if (!disabled) {
      inputRef.current?.focus();
    }
  }, [disabled]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange({ ...event, target: { ...event.target, value: event.target.value } });
  };

  const handleBlur = () => {
    if (inputRef.current) {
      onChange({ target: { value: inputRef.current.value } } as ChangeEvent<HTMLInputElement>);
    }
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      handleBlur();
      setEdit(false);
    }
  };

  return (
    <TextAreaWrapper>
      <TextField
        ref={inputRef}
        disabled={disabled}
        contentRight={
          !loadingImage && disabled ? (
            <Button
              ref={focusButtonRef}
              scaleOnInteraction={false}
              contentLeft={<IconEdit />}
              pin="circle-circle"
              view={'clear'}
              size={'s'}
              onClick={() => {
                setEdit(true);
                inputRef.current?.focus();
              }}
            />
          ) : undefined
        }
        value={value}
        maxLength={maxLength}
        label={'Запрос'}
        onChange={handleInputChange}
        onKeyDown={handleKeyPress}
        onBlur={handleBlur}
        autoFocus={true}
      />
    </TextAreaWrapper>
  );
};

export default CustomTextArea;
