import { FC } from 'react';
import styled from 'styled-components';

interface SlideDotProps {
  selected: boolean;
}

const SlideDot = styled.div<SlideDotProps>`
  width: ${(props) => (props.selected ? '40px' : '10px')};
  height: 10px;
  border-radius: ${(props) => (props.selected ? '16px' : '10px')};
  background-color: ${(props) => (props.selected ? 'white' : 'grey')};
  margin-right: 10px;
  transition: all 0.3s ease;
  cursor: pointer;
  display: inline-block;
`;

type Variant = 'mobile' | 'desktop';

const SliderWrapper = styled.div<{ variant: Variant }>`
  z-index: 9;
  position: absolute;

  left: ${(props) => (props.variant === 'mobile' ? '16px' : 'auto')};
  top: ${(props) => (props.variant === 'mobile' ? '20px' : '43px')};
  right: ${(props) => (props.variant === 'desktop' ? '55px' : 'auto')};
`;

interface SliderProps {
  slides: number;
  activeSlide: number;
  onChange?: (index: number) => void;
  variant: Variant;
}

const Slider: FC<SliderProps> = ({ slides, activeSlide, onChange, variant }) => {
  return (
    <SliderWrapper variant={variant}>
      {Array.from({ length: slides }).map((_, i) => (
        <SlideDot key={i} selected={activeSlide === i} onClick={() => onChange?.(i)} />
      ))}
    </SliderWrapper>
  );
};

export default Slider;
