import { FC, useEffect, useRef, useState, KeyboardEvent } from 'react';
import styled from 'styled-components';
import type { Config } from '@/components/generate/StyleGenerateSelectorDesktop';
import { styleImageConfigs } from '@/components/configs/styleConfigs';
import CardStyleWrapper from '@/components/wrappers/CardStyleWrapper';
import { useGenerateStore } from '@/store/generate';
import { Icon } from '@salutejs/plasma-icons';

interface SelectStyleProps {
  handleCloseStyle: () => void;
}

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 10px;
  padding: 35px;
  width: 100%;
`;

const GridItem = styled.div`
  position: relative;
`;

const SelectStyle: FC<SelectStyleProps> = ({ handleCloseStyle }) => {
  const cardRefs = useRef<(HTMLDivElement | null)[]>([]);
  const modalRef = useRef<HTMLDivElement | null>(null);

  const [focusedIndex, setFocusedIndex] = useState<number>(0);

  const { setImgStyle, imgStyle } = useGenerateStore((state) => ({
    imgStyle: state.imgStyle,
    setImgStyle: state.setImgStyle
  }));

  useEffect(() => {
    const selectedIndex = styleImageConfigs.findIndex(
      (config) => config.styleName === imgStyle.styleName
    );
    const indexToFocus = selectedIndex >= 0 ? selectedIndex : 0;
    setFocusedIndex(indexToFocus);
  }, [imgStyle]);

  const handleStyleSelection = (config: Config) => {
    setImgStyle(config);
    handleCloseStyle();
  };

  const handleKeyDown = (event: KeyboardEvent, config: Config) => {
    if (event.key === 'Enter') {
      handleStyleSelection(config);
    }
  };

  const handleFocusDirect = (index: number) => {
    if (focusedIndex !== index) {
      setFocusedIndex(index);
    }
  };

  return (
    <div ref={modalRef} tabIndex={-1} style={{ outline: 'none' }}>
      <GridContainer>
        {styleImageConfigs.map((config, index) => (
          <GridItem key={config.styleName}>
            <div
              ref={(el) => (cardRefs.current[index] = el)}
              tabIndex={index}
              onFocus={() => handleFocusDirect(index)}
              onKeyDown={(e) => handleKeyDown(e, config)}
            >
              <CardStyleWrapper
                title={config.title}
                image={config.image || ''}
                onClick={() => handleStyleSelection(config)}
                isSelected={focusedIndex === index}
              >
                {config.icon && <Icon icon={config.icon} color={'rgba(255, 255, 255, 0.56)'} />}
              </CardStyleWrapper>
            </div>
          </GridItem>
        ))}
      </GridContainer>
    </div>
  );
};

export default SelectStyle;
