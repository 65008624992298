import Gallery from '@/components/Gallery';
import { useMain } from '@/store/main';
import { useContext, useEffect } from 'react';
import GalleryUserTop from '@/components/GalleryUserTop';
import { SortField } from '@/graphql/graphQlApiHooks';
import { useNavigate } from 'react-router-dom';
import useDeviceType from '@/hooks/useDeviceType';
import TitleBlock from '@/components/TitleBlock';
import { CarouselGridWrapper } from '@salutejs/plasma-ui';
import HelpSliderComponentDesktop from '@/components/howDoesThisWork/HelpSliderComponentDesktop';
import { AssistantContext } from '@/providers/AssistantProvider';

const HomePage = () => {
  const navigate = useNavigate();
  const assistantInstance = useContext(AssistantContext);
  const { isMobile, isDesktop } = useDeviceType();

  const { setTitle } = useMain((state) => ({
    setTitle: state.setTitle
  }));

  useEffect(() => {
    setTitle('Kandinsky');

    // open home метрика
    assistantInstance.assistantRef?.current?.sendData({
      action: { action_id: 'METRIC_MAIN_PAGE' }
    });
  }, []);

  useEffect(() => {
    navigate('/', { replace: true });
    window.history.pushState(null, '', '/');
  }, []);

  return (
    <>
      {isMobile && <GalleryUserTop />}
      {isDesktop && <HelpSliderComponentDesktop />}
      <CarouselGridWrapper>
        <TitleBlock>Лента сообщества</TitleBlock>
      </CarouselGridWrapper>
      <Gallery
        filter={{ skip: 0, limit: 100, isPublic: true, order: { field: SortField.PublishedAt } }}
      />
    </>
  );
};

export default HomePage;
