import { useEffect, useState } from 'react';
import { Button, CarouselGridWrapper, Col, Row } from '@salutejs/plasma-ui';
import Slider from '@/components/SlideDot';
import { useNavigate } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import styled, { keyframes } from 'styled-components';
import { useMain } from '@/store/main';

const fadeIn = keyframes`
    0% { opacity: 0; }
    100% { opacity: 1; }
`;

interface AnimatedDivProps {
  background: string;
}

const AnimatedDiv = styled.div<AnimatedDivProps>`
  background: ${(props) =>
    `url(${props.background}) center bottom / contain no-repeat, linear-gradient(to bottom, #06081d, #070d3c)`};
  height: 38vh;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  color: white;
  text-align: center;
  padding: 20px;
  animation: ${fadeIn} 1s ease-in-out;
`;

const Title = styled.div`
  color: white;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
  margin-bottom: 16px;
  animation: ${fadeIn} 0.5s ease-in-out;
`;

const SubTitle = styled.span`
  color: rgba(255, 255, 255, 0.56);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.32px;
  animation: ${fadeIn} 0.5s ease-in-out;
`;

type Slide = {
  title: string;
  subTitle: string;
  img: string;
};

const SLIDES: Slide[] = [
  {
    title: 'Начните с описания объекта и его деталей',
    subTitle:
      'Опишите или скажите, что бы вы хотели сгенерировать. Например, рыжего кота, красный автомобиль или портрет девушки, добавьте в запрос характерную черту: добрые глаза, дорога или чёрные волосы',
    img: 'images/home/howTo_item_1.jpg'
  },
  {
    title: 'Затем добавьте описание фона',
    subTitle:
      'Например, на заднем фоне снежные горы; на заднем фоне город, закатное солнце; или на заднем фоне лес с цветами',
    img: 'images/home/howTo_item_2.jpg'
  },
  {
    title: 'Определите стиль',
    subTitle:
      'Выберите стиль из предложенных или добавьте описание стиля словами. Например, скриншот DVD из фильма, ретро-аниме; поп-арт; или кинематографичный свет, детализация, 4K',
    img: 'images/home/howTo_item_3.jpg'
  }
];

const HelpPage = () => {
  const [activeSlide, setActiveSlide] = useState(0);
  const [goToHome, setGoToHome] = useState(false);
  const navigate = useNavigate();

  const { setTitle, setIcon, setHideForShowHelpSliderComponent } = useMain((state) => ({
    setTitle: state.setTitle,
    setIcon: state.setIcon,
    setHideForShowHelpSliderComponent: state.setHideForShowHelpSliderComponent
  }));

  const nextSlide = () => {
    setActiveSlide((currentSlide) => {
      const nextSlide = currentSlide + 1 >= SLIDES.length ? 0 : currentSlide + 1;
      if (nextSlide === 0) {
        setGoToHome(true);
      }
      return nextSlide;
    });
  };

  const previousSlide = () => {
    setActiveSlide((currentSlide) => {
      return currentSlide - 1 < 0 ? 0 : currentSlide - 1;
    });
  };

  useEffect(() => {
    if (goToHome) {
      setHideForShowHelpSliderComponent();
      navigate('/generate', { replace: true });
    }
  }, [goToHome, navigate]);

  const handlers = useSwipeable({
    onSwipedLeft: () => nextSlide(),
    onSwipedRight: () => previousSlide(),
    trackMouse: false
  });

  useEffect(() => {
    setTitle('');
    setIcon('');

    return () => {
      setTitle('Kandinsky');
      setIcon('/logo.png');
    };
  }, []);

  return (
    <div {...handlers}>
      {SLIDES[activeSlide] && (
        <CarouselGridWrapper
          style={{ borderRadius: '24px 24px 0 0', position: 'fixed', top: 0, left: 0 }}
        >
          <Row>
            <Col size={12}>
              <AnimatedDiv key={activeSlide} background={SLIDES[activeSlide].img} />
            </Col>
            <Col size={12}>
              <div style={{ textAlign: 'center', padding: '32px', height: '100%' }}>
                <Title key={SLIDES[activeSlide].title}>{SLIDES[activeSlide].title}</Title>
                <SubTitle key={SLIDES[activeSlide].subTitle}>
                  {SLIDES[activeSlide].subTitle}
                </SubTitle>

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    position: 'fixed',
                    width: 'calc(100% - 2rem)',
                    bottom: '80px',
                    flexDirection: 'row-reverse'
                  }}
                >
                  <Slider
                    variant="mobile"
                    slides={SLIDES.length}
                    activeSlide={activeSlide}
                    onChange={setActiveSlide}
                  />
                  <Button
                    scaleOnInteraction={false}
                    text={activeSlide + 1 === SLIDES.length ? 'К созданию' : 'Дальше'}
                    view={'primary'}
                    onClick={nextSlide}
                    style={{ width: 'calc(40vw - 10px)', opacity: 1 }}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </CarouselGridWrapper>
      )}
    </div>
  );
};

export default HelpPage;
