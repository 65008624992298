import { FC, ReactNode, useContext, useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import HomePage from '@/pages/HomePage';
import GalleryUser from '@/pages/GalleryUser';
import AppLayout from '@/layouts/AppLayout';
import { AssistantContext } from '@/providers/AssistantProvider';
import { Container, NeuHeader } from '@salutejs/plasma-ui';
import ImageInfoPage from '@/pages/ImageInfoPage';
import HelpPage from '@/pages/HelpPage';
import HeaderMenuDesktop from '@/components/HeaderMenuDesktop';
import { useMain } from '@/store/main';
import GeneratePage from '@/pages/GeneratePage';
import LoadingComponent from '@/components/Loading';
import { SortField } from '@/graphql/graphQlApiHooks';

interface RouteItem {
  route: string;
  component: ReactNode;
  collapse?: RouteItem[];
}

const generateRoutes = (): RouteItem[] => [
  { route: '/', component: <HomePage /> },
  {
    route: '/gallery',
    component: (
      <GalleryUser filter={{ skip: 0, limit: 100, order: { field: SortField.CreatedAt } }} />
    )
  },
  { route: '/gallery/:id', component: <ImageInfoPage /> },
  {
    route: '/favorites',
    component: (
      <GalleryUser
        filter={{ isMyLike: true, skip: 0, limit: 100, order: { field: SortField.UpdatedAt } }}
      />
    )
  },
  { route: '/favorites/:id', component: <ImageInfoPage /> },
  {
    route: '/all-generated',
    component: (
      <GalleryUser
        filter={{ isMy: true, skip: 0, limit: 100, order: { field: SortField.CreatedAt } }}
      />
    )
  },
  { route: '/all-generated/:id', component: <ImageInfoPage /> },
  { route: '/generate', component: <GeneratePage /> },
  { route: '/generate/:id', component: <ImageInfoPage /> },
  { route: '/:id', component: <ImageInfoPage /> },
  { route: '/help', component: <HelpPage /> }
];

const getRoutes = (allRoutes: RouteItem[]): ReactNode[] =>
  allRoutes.flatMap((route: RouteItem) => {
    if (route.collapse) {
      return getRoutes(route.collapse);
    }

    if (route.route) {
      return (
        <Route
          key={route.route}
          path={route.route}
          element={<AppLayout>{route.component}</AppLayout>}
        />
      );
    }

    return null;
  });

const AppRoutes: FC = () => {
  const assistantInstance = useContext(AssistantContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { setGradient } = useMain();

  const { title, icon } = useMain((state) => ({
    title: state.title,
    icon: state.icon
  }));

  useEffect(() => {
    setGradient(
      'linear-gradient(180deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0.05) 100%), #080808'
    );
    const broadcast = new BroadcastChannel('generationActions');

    const handleMessage = (event: MessageEvent) => {
      const { action } = event.data;

      switch (action) {
        case 'openFavorites':
          navigate('/favorites', { replace: true });
          break;
      }
    };

    broadcast.addEventListener('message', handleMessage);

    return () => {
      broadcast.removeEventListener('message', handleMessage);
      broadcast.close();
    };
  }, [navigate]);

  const handleArrowClick = () => {
    if (location.pathname !== '/') {
      navigate('/', { replace: true });
      window.history.pushState({ page: 'page' }, '', '/');
    } else {
      assistantInstance.assistantRef?.current?.sendData({ action: { action_id: 'CLOSE_APP' } });
    }
  };

  if (!assistantInstance || !assistantInstance.userId) {
    return <LoadingComponent />;
  }

  const routes: RouteItem[] = generateRoutes();
  const renderedRoutes = getRoutes(routes);

  const isHome = location.pathname === '/';

  return (
    <>
      <div className="header-hover"></div>
      <Container style={{ zIndex: 9, top: 0 }}>
        <NeuHeader
          className={'header-icon'}
          title={title}
          arrow={!isHome ? 'back' : 'minimize'}
          onArrowClick={handleArrowClick}
          logo={icon}
          style={{
            zIndex: 9,
            position: 'fixed',
            width: 'calc(100vw - var(--plasma-grid-margin) * 2)',
            paddingTop: 'calc(var(--plasma-header-pt) + 5px)',
            paddingBottom: 'calc(var(--plasma-header-pb) + 5px)'
          }}
        >
          <HeaderMenuDesktop />
        </NeuHeader>
      </Container>
      <div className="header-height">
        <Routes>{renderedRoutes}</Routes>
      </div>
    </>
  );
};

export default AppRoutes;
