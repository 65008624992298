import { FC } from 'react';
import { Button, Sheet, CardParagraph1, Headline2 } from '@salutejs/plasma-ui';
import useDeviceType from '@/hooks/useDeviceType';

interface ConfirmationModalProps {
  isOpen: boolean;
  title: string;
  subtitle: string;
  onConfirm: () => void;
  onCancel: () => void;
}

const ConfirmationModal: FC<ConfirmationModalProps> = ({
  isOpen,
  title,
  subtitle,
  onConfirm,
  onCancel
}) => {
  const { isMobile } = useDeviceType();

  if (!isOpen) {
    return null;
  }

  return (
    <Sheet
      isOpen={isOpen}
      opened={isOpen}
      withOverlay={true}
      withTransition={true}
      onClose={onCancel}
    >
      <div
        style={{
          padding: isMobile ? '0' : '2rem',
          maxWidth: '412px',
          margin: '0 auto'
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
            gap: '8px',
            paddingTop: '24px',
            paddingBottom: '22px'
          }}
        >
          <Headline2>{title}</Headline2>
          <CardParagraph1>{subtitle}</CardParagraph1>
        </div>
        <div style={{ paddingBottom: '80px' }}>
          <Button
            scaleOnInteraction={false}
            text={'Отмена'}
            onClick={onCancel}
            size="s"
            view={'secondary'}
            style={{ width: '100%', marginBottom: '0.3rem', height: '3rem' }}
          />
          <Button
            scaleOnInteraction={false}
            text={'Удалить'}
            onClick={onConfirm}
            size="s"
            view={'warning'}
            style={{
              width: '100%',
              height: '3rem',
              marginTop: '8px',
              marginBottom: '22px',
              opacity: 1,
              backgroundColor: '#FF2E43'
            }}
          />
        </div>
      </div>
    </Sheet>
  );
};

export default ConfirmationModal;
