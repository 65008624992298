import { ChangeEvent, FC, RefObject } from 'react';
import { Row, Col, Button, CarouselGridWrapper } from '@salutejs/plasma-ui';

import PromptAssistant from '@/components/generate/PromptAssistant';
import ActionButtons from '@/components/ActionButtons';
import CustomTextArea from '@/components/CustomTextArea';
import ImageWitchPoetry from '@/components/ImageWitchPoetry';
import BtnShare from '@/components/BtnShare';
import StyleGenerateSelectorMobile from '@/components/generate/StyleGenerateSelectorMobile';
import FullScreenImage from '@/components/FullScreenImage';
import FloatingBottomWrapper from '@/components/wrappers/FloatingBottomWrapper';
import TitleBlock from '@/components/TitleBlock';
import { useGenerateStore } from '@/store/generate';
import { usePostCardStore } from '@/store/postCard';
import { GenerationStatus } from '@/graphql/graphQlApiHooks';

interface GeneratePageMobileProps {
  handleInputChange: (e: ChangeEvent<HTMLInputElement>) => void;
  handleOpenFullScreen: () => void;
  handleCloseFullScreen: () => void;
  showFullScreen: boolean;
  startGenerate: () => void;
  startRegenerate: () => void;
  focusButtonRef: RefObject<HTMLButtonElement>;
}

const GeneratePageMobile: FC<GeneratePageMobileProps> = ({
  handleInputChange,
  handleOpenFullScreen,
  handleCloseFullScreen,
  showFullScreen,
  startGenerate,
  startRegenerate,
  focusButtonRef
}) => {
  const { edit, prompt, postCards, currentPostCardIndex, nextCard, previousCard } =
    useGenerateStore((state) => ({
      edit: state.edit,
      currentPostCardIndex: state.currentPostCardIndex,
      prompt: state.prompt,
      postCards: state.postCards,
      nextCard: state.nextCard,
      previousCard: state.previousCard
    }));

  const { postCard } = usePostCardStore((state) => ({
    postCard: state.postCard
  }));

  const startCreateIfThereCreated = () => {
    if (currentPostCardIndex !== -1 && prompt) startRegenerate();
  };

  return (
    <>
      <Row>
        {postCard && (
          <Col sizeS={4} sizeM={6} sizeL={4} sizeXL={5}>
            <div onClick={handleOpenFullScreen} style={{ marginBottom: '14px' }}>
              <ImageWitchPoetry key={postCard.id} postCard={postCard} />
            </div>
          </Col>
        )}

        <Col
          sizeS={postCard ? 4 : 8}
          sizeM={6}
          sizeL={postCard ? 4 : 8}
          sizeXL={postCard ? 5 : 12}
          offsetXL={1}
        >
          <CustomTextArea
            disabled={!edit}
            loading={postCard?.image?.status === GenerationStatus.Pending}
            value={prompt}
            onChange={handleInputChange}
            maxLength={140}
          />

          {edit && (
            <CarouselGridWrapper>
              <PromptAssistant />
            </CarouselGridWrapper>
          )}

          <CarouselGridWrapper>
            <TitleBlock>СТИЛЬ</TitleBlock>
            <StyleGenerateSelectorMobile
              disable={postCard?.image?.status === GenerationStatus.Pending}
              startGenerate={startCreateIfThereCreated}
            />
          </CarouselGridWrapper>

          {currentPostCardIndex >= 0 && (
            <>
              <ActionButtons
                style={{ margin: '0.5rem 0px' }}
                onCancel={previousCard}
                onRetry={startRegenerate}
                onRepeat={nextCard}
                disableCancel={currentPostCardIndex === 0}
                disableRetry={!edit && !postCard?.image?.imageUrl}
                disableNext={postCards.length === currentPostCardIndex + 1}
              />

              <div
                style={{
                  display: 'flex',
                  gap: '8px',
                  width: 'calc(100vw - var(--plasma-grid-margin) * 2)',
                  position: 'fixed',
                  bottom: '80px'
                }}
              >
                {postCard?.image?.imageUrl && (
                  <>
                    <BtnShare />
                    {/*// TODO: отключение публикации*/}
                    {/*<BtnPublish*/}
                    {/*  text="Опубликовать"*/}
                    {/*  style={{*/}
                    {/*    minWidth: '50%',*/}
                    {/*    opacity: 1,*/}
                    {/*    background: '#21A038',*/}
                    {/*    color: 'white',*/}
                    {/*    marginTop: '8px'*/}
                    {/*  }}*/}
                    {/*/>*/}
                  </>
                )}
              </div>
            </>
          )}
        </Col>
      </Row>

      {currentPostCardIndex === -1 && (
        <FloatingBottomWrapper>
          <Button
            ref={focusButtonRef}
            scaleOnInteraction={false}
            disabled={!prompt.length}
            text="Сгенерировать"
            onTouchStart={prompt.length ? startGenerate : () => ''}
            onClick={prompt.length ? startGenerate : () => ''}
            view="primary"
            style={{ width: 'calc(100vw - var(--plasma-grid-margin) * 2)' }}
          />
        </FloatingBottomWrapper>
      )}

      {showFullScreen && postCard?.image?.imageUrl && (
        <FullScreenImage src={postCard.image.imageUrl} onClose={handleCloseFullScreen} />
      )}
    </>
  );
};

export default GeneratePageMobile;
