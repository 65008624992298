import { FC } from 'react';
import Gallery from '@/components/Gallery';
import TitleBlock from '@/components/TitleBlock';
import { CarouselGridWrapper } from '@salutejs/plasma-ui';
import { PostcardFilterInput, useGetPostCardsQuery } from '@/graphql/graphQlApiHooks';

interface GalleryUserProps {
  filter: PostcardFilterInput;
}
const GalleryUser: FC<GalleryUserProps> = ({ filter }) => {
  const { data } = useGetPostCardsQuery({
    variables: {
      input: filter
    },
    fetchPolicy: 'cache-and-network'
  });

  return (
    <>
      {!!data?.getPostCards?.length && (
        <CarouselGridWrapper>
          <TitleBlock>{filter.isMyLike ? 'Избранное' : 'Созданные'}</TitleBlock>
        </CarouselGridWrapper>
      )}
      <Gallery filter={filter} />
    </>
  );
};

export default GalleryUser;
