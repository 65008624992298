import { FC, useEffect } from 'react';
import QRCode from 'react-qr-code';

interface FullScreenQRCodeProps {
  link: string;
  onClose: () => void;
}

const FullScreenQRCode: FC<FullScreenQRCodeProps> = ({ link, onClose }) => {
  useEffect(() => {
    const handleKeyDown = () => {
      onClose();
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [onClose]);

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 9999,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#000'
      }}
    >
      <p
        style={{
          color: 'white',
          marginBottom: '1rem',
          fontSize: '2.5vh',
          fontWeight: 600,
          lineHeight: '3vh',
          textAlign: 'center',
          maxWidth: '80vw'
        }}
      >
        Отсканируйте QR-код камерой телефона, чтобы открыть ссылку на рисунок
      </p>
      <div className={'qr-wrapper'}>
        <QRCode value={link} style={{ width: '100%', height: '100%' }} />
      </div>
    </div>
  );
};

export default FullScreenQRCode;
