import { ChangeEvent, FC, RefObject, useContext, useEffect, useState } from 'react';
import { Row, Col, Button } from '@salutejs/plasma-ui';

import ActionButtons from '@/components/ActionButtons';
import BtnShare from '@/components/BtnShare';
import CustomTextArea from '@/components/CustomTextArea';
import ImageWitchPoetry from '@/components/ImageWitchPoetry';
import StyleGenerateSelectorDesktop from '@/components/generate/StyleGenerateSelectorDesktop';
import FullScreenImage from '@/components/FullScreenImage';
import { useGenerateStore } from '@/store/generate';
import { usePostCardStore } from '@/store/postCard';
import GenerateScreenDesktop from '@/components/generateDesktop/GenerateScreenDesktop';
import useDeviceType from '@/hooks/useDeviceType';
import { AssistantContext } from '@/providers/AssistantProvider';

interface GeneratePageDesktopProps {
  handleInputChange: (e: ChangeEvent<HTMLInputElement>) => void;
  handleOpenFullScreen: () => void;
  handleCloseFullScreen: () => void;
  showFullScreen: boolean;
  startGenerate: () => void;
  startRegenerate: () => void;
  focusButtonRef: RefObject<HTMLButtonElement>;
}

const GeneratePageDesktop: FC<GeneratePageDesktopProps> = ({
  handleInputChange,
  handleOpenFullScreen,
  handleCloseFullScreen,
  showFullScreen,
  startGenerate,
  startRegenerate,
  focusButtonRef
}) => {
  const [isScreenVisible, setIsScreenVisible] = useState(true);
  const [isGenerateCommand, setIsGenerateCommand] = useState(false);

  const { generatePhrase } = useContext(AssistantContext);

  const { edit, prompt, postCards, currentPostCardIndex, nextCard, previousCard, setPrompt } =
    useGenerateStore((state) => ({
      setPrompt: state.setPrompt,
      edit: state.edit,
      currentPostCardIndex: state.currentPostCardIndex,
      prompt: state.prompt,
      postCards: state.postCards,
      nextCard: state.nextCard,
      previousCard: state.previousCard
    }));

  const { postCard } = usePostCardStore((state) => ({
    postCard: state.postCard
  }));

  const { isDesktop } = useDeviceType();

  useEffect(() => {
    if (generatePhrase) {
      setPrompt(generatePhrase.image);
      setIsGenerateCommand(true);
    }
  }, [generatePhrase]);

  useEffect(() => {
    if (isGenerateCommand) {
      startGenerate();
      setIsGenerateCommand(false);
    }
  }, [isGenerateCommand]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        setIsScreenVisible(false);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  if (isDesktop && isScreenVisible && postCards.length === 0 && !prompt) {
    return <GenerateScreenDesktop />;
  }

  if (isDesktop && !postCard && isScreenVisible) return null;

  return (
    <>
      <Row style={postCard ? {} : { marginTop: '10vh' }}>
        {postCard && (
          <Col sizeS={4} sizeM={6} sizeL={4} sizeXL={5}>
            <div onClick={handleOpenFullScreen} style={{ marginBottom: '14px' }}>
              <ImageWitchPoetry key={postCard.id} postCard={postCard} />
            </div>
          </Col>
        )}

        <Col
          sizeS={postCard ? 4 : 8}
          sizeM={6}
          sizeL={postCard ? 4 : 8}
          sizeXL={postCard ? 5 : 6}
          offsetXL={postCard ? 1 : 3}
        >
          <CustomTextArea
            disabled={!edit}
            loading={!postCard?.image?.imageUrl}
            value={prompt}
            onChange={handleInputChange}
            maxLength={140}
          />

          <StyleGenerateSelectorDesktop />

          {currentPostCardIndex >= 0 && (
            <>
              <ActionButtons
                style={{ margin: '0.5rem 0px' }}
                onCancel={previousCard}
                onRetry={startRegenerate}
                onRepeat={nextCard}
                disableCancel={currentPostCardIndex === 0}
                disableRetry={!edit && !postCard?.image?.imageUrl}
                disableNext={postCards.length === currentPostCardIndex + 1}
              />
              <div style={{ margin: '14px 0' }}>
                <BtnShare />
              </div>
              {/*// TODO: отключение публикации*/}
              {/*<BtnPublish />*/}
            </>
          )}

          {currentPostCardIndex === -1 && (
            <Button
              ref={focusButtonRef}
              scaleOnInteraction={false}
              disabled={!prompt.length}
              text="Сгенерировать"
              onTouchStart={prompt.length ? startGenerate : () => ''}
              onClick={prompt.length ? startGenerate : () => ''}
              view="primary"
              style={{ width: '100%' }}
            />
          )}
        </Col>
      </Row>

      {showFullScreen && postCard?.image?.imageUrl && (
        <FullScreenImage src={postCard.image.imageUrl} onClose={handleCloseFullScreen} />
      )}
    </>
  );
};

export default GeneratePageDesktop;
