import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  position: absolute;
  align-items: center;
  gap: 1vw;
  background-color: rgb(30, 30, 30);
  padding: 1vw;
  border-radius: 1vw;
  top: calc(-1 * var(--plasma-grid-margin));
  z-index: 99;
  right: 0;
`;

const OuterCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3vw;
  height: 3vw;
  border-radius: 50%;
  background-color: #5a5a5a;
`;

const InnerCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.5vw;
  height: 1.5vw;
  border-radius: 50%;
  background-color: #fff;
  font-weight: bold;
  font-size: 0.8vw;
  color: #000;
`;

const Text = styled.div`
  font-size: 0.8vw;
  color: #fff;
`;

const CircleTextComponent = () => {
  return (
    <Container>
      <OuterCircle>
        <InnerCircle>OK</InnerCircle>
      </OuterCircle>
      <Text>
        Нажмите ОК, чтобы открыть <br /> ввод с клавиатуры
      </Text>
    </Container>
  );
};

export default CircleTextComponent;
