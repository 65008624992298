import { ChangeEvent, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGenerateStore } from '@/store/generate';
import { usePostCardStore } from '@/store/postCard';
import { useMain } from '@/store/main';
import useImageManager from '@/hooks/useImageManager';
import useDeviceType from '@/hooks/useDeviceType';

import GeneratePageDesktop from './GeneratePageDesktop';
import GeneratePageMobile from './GeneratePageMobile';
import { AssistantContext } from '@/providers/AssistantProvider';

const GeneratePage = () => {
  const [showFullScreen, setShowFullScreen] = useState(false);
  const focusButtonRef = useRef<HTMLButtonElement>(null);
  const assistantInstance = useContext(AssistantContext);
  const navigate = useNavigate();
  const { isMobile, isDesktop } = useDeviceType();

  const { setTitle, setIcon } = useMain((state) => ({
    setTitle: state.setTitle,
    setIcon: state.setIcon
  }));

  const {
    setEdit,
    prompt,
    setPrompt,
    postCards,
    currentPostCardIndex,
    setCurrentCardIndex,
    clearAll,
    clearSuggestion
  } = useGenerateStore((state) => ({
    setEdit: state.setEdit,
    currentPostCardIndex: state.currentPostCardIndex,
    setCurrentCardIndex: state.setCurrentCardIndex,
    prompt: state.prompt,
    postCards: state.postCards,
    setPrompt: state.setPrompt,
    clearAll: state.clearAll,
    clearSuggestion: state.clearSuggestion
  }));

  const { postCard, setPostCard, clearPostCard } = usePostCardStore((state) => ({
    setPostCard: state.setPostCard,
    postCard: state.postCard,
    clearPostCard: state.clearPostCard
  }));

  const { generate } = useImageManager();

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPrompt(event.target.value);
  };

  const startGenerate = async (regenerate = false) => {
    setEdit(false);
    if (!prompt && postCard) {
      setPrompt(postCard.prompt);
    }
    if (prompt) {
      const id = await generate();

      // генерация изображения метрика
      console.log('METRIC_GENERATE_IMAGE', {
        action_id: 'METRIC_GENERATE_IMAGE',
        parameters: { id, regenerate }
      });
      assistantInstance.assistantRef?.current?.sendData({
        action: { action_id: 'METRIC_GENERATE_IMAGE', parameters: { id, regenerate } }
      });
    }
  };

  useEffect(() => {
    return () => {
      clearAll();
      clearPostCard();
      clearSuggestion();
    };
  }, []);

  useEffect(() => {
    focusButtonRef?.current?.focus();
  }, []);

  useEffect(() => {
    setIcon('');
    setTitle('');

    if (isMobile && !postCard?.image?.imageUrl && !prompt) {
      setTitle('Создание рисунка');
    }

    if (isDesktop && !postCard?.image?.imageUrl && !prompt) {
      setTitle('Создание запроса');
    }

    return () => {
      setTitle('Kandinsky');
      setIcon('/logo.png');
    };
  }, [postCards, prompt, isDesktop, isMobile]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.get('modal') === 'open') {
      setShowFullScreen(true);
    } else {
      setShowFullScreen(false);
    }
  }, [location.search]);

  useEffect(() => {
    if (postCards[currentPostCardIndex]) {
      setPostCard(postCards[currentPostCardIndex]);
      return;
    }
    setPostCard(postCards[postCards.length - 1]);
    setCurrentCardIndex(postCards.length - 1);
  }, [currentPostCardIndex, postCards]);

  const handleCloseFullScreen = () => {
    setShowFullScreen(false);
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete('modal');
    navigate(
      `${location.pathname.replace(/\/$/, '')}${
        searchParams.toString() ? `?${searchParams.toString()}` : ''
      }`,
      { replace: true }
    );
  };

  const handleOpenFullScreen = () => {
    setShowFullScreen(true);
    navigate(`${location.pathname}/?modal=open`);
  };

  return (
    <>
      {isDesktop ? (
        <GeneratePageDesktop
          handleInputChange={handleInputChange}
          handleOpenFullScreen={handleOpenFullScreen}
          handleCloseFullScreen={handleCloseFullScreen}
          showFullScreen={showFullScreen}
          startGenerate={() => startGenerate(false)}
          startRegenerate={() => startGenerate(true)}
          focusButtonRef={focusButtonRef}
        />
      ) : (
        <GeneratePageMobile
          handleInputChange={handleInputChange}
          handleOpenFullScreen={handleOpenFullScreen}
          handleCloseFullScreen={handleCloseFullScreen}
          showFullScreen={showFullScreen}
          startGenerate={() => startGenerate(false)}
          startRegenerate={() => startGenerate(true)}
          focusButtonRef={focusButtonRef}
        />
      )}
    </>
  );
};

export default GeneratePage;
