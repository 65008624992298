interface AmplitudeConfig {
  device_id: string;
  user_id: string;
  app_name: string;
  surface: string;
  channel: string;
}

export interface ImageOperationEventData {
  type: string;
  user_id: string;
  timecreated: string;
  image_id: string;
  prompt: string;
  image_url: string;
}

export interface TelemetryEvent {
  event_type: string;
  message_id: string;
  event_properties: {
    timecreated: string;
    ImageOperationEvent?: ImageOperationEventData;
    [key: string]: any;
  };
}

class AmplitudeService {
  private static instance: AmplitudeService;
  private config: AmplitudeConfig;
  private apiKey: string;
  private serverUrl: string;

  private constructor(config: AmplitudeConfig, apiKey: string, serverUrl: string) {
    this.config = config;
    this.apiKey = apiKey;
    this.serverUrl = serverUrl;
  }

  public static getInstance(
    config?: AmplitudeConfig,
    apiKey?: string,
    serverUrl?: string
  ): AmplitudeService {
    if (!AmplitudeService.instance) {
      if (!config || !apiKey || !serverUrl) {
        throw new Error('Инициализация AmplitudeService требует config, apiKey и serverUrl.');
      }
      AmplitudeService.instance = new AmplitudeService(config, apiKey, serverUrl);
    }
    return AmplitudeService.instance;
  }

  private buildEvent(event: TelemetryEvent) {
    return {
      time: Math.floor(Date.now() / 1000),
      device_id: this.config.device_id,
      user_id: this.config.user_id,
      app_name: this.config.app_name,
      surface: this.config.surface,
      channel: this.config.channel,
      ...event
    };
  }

  public sendEvent(event: TelemetryEvent): void {
    const fullEvent = this.buildEvent(event);
    const payload = { events: [fullEvent] };

    fetch(this.serverUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-API-KEY': this.apiKey
      },
      body: JSON.stringify(payload)
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Ошибка: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => console.log('ответ:', data))
      .catch((error) => console.error('Ошибка отправки события:', error));
  }
}

export default AmplitudeService;
