import { FC, ReactNode } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 100vw;
  overflow-x: scroll;
  white-space: nowrap;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const Content = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: calc(var(--gap) / 2);

  overflow-x: auto;
  overscroll-behavior-x: contain;
  scroll-snap-type: x mandatory;
  scroll-padding-inline: var(--gap);
  //padding: 1rem var(--plasma-grid-margin) 1rem 1rem;
  margin-bottom: 8px;
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const Inner = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  margin-left: var(--plasma-grid-margin);
`;

interface ScrollWrapperProps {
  children: ReactNode;
}

const ScrollWrapper: FC<ScrollWrapperProps> = ({ children }) => {
  return (
    <Container>
      <Content>
        <Inner>{children}</Inner>
      </Content>
    </Container>
  );
};

export default ScrollWrapper;
