import ReactDOM from 'react-dom/client';
import App from './App';
import { HelmetProvider } from 'react-helmet-async';
import { SSRProvider } from '@salutejs/plasma-ui';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '@/assets/css/index.css';
import { AssistantProvider } from '@/providers/AssistantProvider';
import ApolloProviderChangeToken from '@/providers/ApolloProviderChangeToken';
import { BrowserRouter } from 'react-router-dom';
import './sentry';
import AmplitudeService from './utils/amplitudeService';

AmplitudeService.getInstance(
  {
    device_id: 'a9571e189efe8b4a76fc7156bd9aa349',
    user_id: '00CA0001MAPPCI03466335SOWATOGC',
    app_name: 'Kandinsky',
    surface: 'TV',
    channel: 'B2C'
  },
  '21bb6ffef5565aa193393ee4d4f0428e',
  'https://cbdv.dev.sberdevices.ru/amplitude/telemetry'
);

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <SSRProvider>
    <AssistantProvider>
      <ApolloProviderChangeToken>
        <HelmetProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </HelmetProvider>
      </ApolloProviderChangeToken>
    </AssistantProvider>
  </SSRProvider>
);
