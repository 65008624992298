import { useState, useEffect, useContext } from 'react';
import './generate-screen-desktop.css';
import { AssistantContext } from '@/providers/AssistantProvider';
import CircleTextComponent from '@/components/generateDesktop/CircleTextComponent';

const variants = [
  {
    text: 'Cпортивный автомобиль едет по дороге, а на фоне рассвет, облака, качество 4К',
    image: '/images/home/01.jpg'
  },
  {
    text: 'Портрет рыжего кота с добрыми глазами, на заднем фоне снежные горы',
    image: '/images/home/02.jpg'
  },
  {
    text: 'Портрет девушки с красивыми глазами на фоне яркого заката',
    image: '/images/home/03.jpg'
  }
];

const GenerateScreenDesktop = () => {
  const [index, setIndex] = useState(0);

  const assistantInstance = useContext(AssistantContext);

  useEffect(() => {
    const timer = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % variants.length);
    }, 10000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    assistantInstance.assistantRef?.current?.sendData({
      action: { action_id: 'GENERATE' }
    });
  }, []);

  return (
    <div className="empty-screen">
      <CircleTextComponent />

      <img className="rotated-image" src={variants[index].image} alt="Variant Image" />
      <div className="center-content">
        <img src="/images/home/btn.png" alt="Above Text" />
        <div className="sub-text">Нажмите и скажите нарисуй</div>
        <span className="main-text">{variants[index].text}</span>
      </div>
    </div>
  );
};

export default GenerateScreenDesktop;
