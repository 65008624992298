import { FC, useContext, useState } from 'react';
import { useDeletePostcardMutation } from '@/graphql/graphQlApiHooks';
import ConfirmationModal from '@/components/ConformationSheet';
import { Button, useToast } from '@salutejs/plasma-ui';
import { usePostCardStore } from '@/store/postCard';
import { useCheckWitch } from '@/hooks/useCheckWitch';
import { Icon } from '@salutejs/plasma-icons';
import AmplitudeService from '@/utils/amplitudeService';
import { AssistantContext } from '@/providers/AssistantProvider';

interface BtnDeleteProps {
  onClick?: (id: string) => void;
}

const BtnDeleteDesktop: FC<BtnDeleteProps> = ({ onClick }) => {
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const assistantInstance = useContext(AssistantContext);

  const { postCard } = usePostCardStore((state) => ({
    postCard: state.postCard
  }));

  const [postcard_Delete] = useDeletePostcardMutation();
  const { showToast } = useToast();
  const { onMoreWidth } = useCheckWitch();

  const handleDelete = () => {
    if (postCard?.id) {
      setConfirmationOpen(true);
    }
  };

  const handleConfirm = async () => {
    if (postCard?.id) {
      try {
        await postcard_Delete({
          variables: {
            input: { id: postCard.id }
          }
        });
        showToast({ text: 'Генерация успешно удалена', timeout: 1000 });
        onClick && onClick(postCard.id);

        // удаление изображения метрика
        assistantInstance.assistantRef?.current?.sendData({
          action: { action_id: 'METRIC_DELETE_IMAGE', parameters: postCard.image }
        });
      } catch (e) {
        console.error(e);
      }
    }
    setConfirmationOpen(false);
  };

  const handleCancel = () => {
    setConfirmationOpen(false);
  };

  if (!postCard?.image?.imageUrl || !postCard?.isMy) return null;

  return (
    <>
      <div className={'btn-delete'}>
        <Button
          scaleOnInteraction={false}
          size="s"
          view={onMoreWidth(560) ? 'secondary' : 'clear'}
          onClick={handleDelete}
          text={onMoreWidth(560) ? 'Удалить' : ''}
          style={{ marginRight: '8px' }}
          contentLeft={<Icon icon={'trash'} />}
        />
      </div>
      <ConfirmationModal
        isOpen={confirmationOpen}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        title={'Удалить эту генерацию?'}
        subtitle={'Данная публикация станет неактуальной'}
      />
    </>
  );
};

export default BtnDeleteDesktop;
