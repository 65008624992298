import { DeviceThemeProvider, ToastProvider } from '@salutejs/plasma-ui';
import Routes from '@/routes';
import { GlobalStyle } from '@/layouts/GlobalStyle';
import styled from 'styled-components';
import { useCallback, useContext, useEffect, useState } from 'react';
import { AssistantContext } from '@/providers/AssistantProvider';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { GradientBackground } from '@/components/GradientBackground';
import { useMain } from '@/store/main';
import { useGenerateStore } from '@/store/generate';

const regExpByPlatform = {
  sberbox: /\b(?:sberbox|sberboxtime|satellite|satellite1|tv)\b/i,
  portal: /\b(?:sberportal|stargate)\b/i,
  mobile: /./
};

const detectPlatformByUserAgent = (userAgent: string) => {
  for (const [platform, regExp] of Object.entries(regExpByPlatform)) {
    if (regExp.test(userAgent)) {
      return platform;
    }
  }
  return 'mobile';
};

const AppContainer = styled.div`
  padding: 0 0 20vh;

  @media (min-width: 1024px) {
    padding: 0 0 10vh;
  }
`;

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const [platform, setPlatform] = useState('unknown');

  useEffect(() => {
    setPlatform(detectPlatformByUserAgent(navigator.userAgent));
  }, []);

  const detectDeviceCallback = useCallback(() => {
    switch (platform) {
      case 'mobile':
        return 'mobile';
      case 'portal':
        return 'sberPortal';
      case 'sberbox':
        return 'sberBox';
      default:
        return 'mobile';
    }
  }, [platform]);

  const isGenerate = location.pathname === '/generate';
  const { generatePhrase } = useContext(AssistantContext);
  const { gradient } = useMain();
  const { setPrompt } = useGenerateStore((state) => ({
    setPrompt: state.setPrompt
  }));

  useEffect(() => {
    if (generatePhrase && !isGenerate) {
      setPrompt(generatePhrase.image);
      navigate('/generate');
    }
  }, [generatePhrase]);

  useEffect(() => {
    const deviceInfo = {
      navigator: navigator,
      platform: platform,
      pathname: location.pathname
    };

    Sentry.captureMessage('userAgent', {
      level: 'info',
      extra: deviceInfo
    });
  }, [platform, location.pathname]);

  return (
    <DeviceThemeProvider detectDeviceCallback={detectDeviceCallback}>
      <ToastProvider>
        <GradientBackground gradient={gradient} />
        <AppContainer>
          <GlobalStyle />
          <Routes />
        </AppContainer>
      </ToastProvider>
    </DeviceThemeProvider>
  );
}

export default App;
