import { FC, ReactNode } from 'react';
import { Container } from '@salutejs/plasma-ui';

interface Props {
  children: ReactNode;
}

const AppLayout: FC<Props> = ({ children }) => {
  return <Container>{children}</Container>;
};

export default AppLayout;
