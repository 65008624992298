import { FC } from 'react';
import styled from 'styled-components';

interface IconProps {
  opacity?: string;
  color?: string;
}
export const TrashIcon: FC<IconProps> = ({ opacity }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Trash / ic_24_trash_fill">
      <g id="trash_fill">
        <path
          d="M8 5.5C8 4.11929 9.11929 3 10.5 3H13.5C14.8807 3 16 4.11929 16 5.5V6.4367H14V5.5C14 5.22386 13.7761 5 13.5 5H10.5C10.2239 5 10 5.22386 10 5.5V6.4367H8V5.5Z"
          fill="white"
          fillOpacity={opacity ? opacity : 0.56}
        />
        <path
          d="M4 9C4 8.44772 4.44772 8 5 8H19C19.5523 8 20 8.44772 20 9C20 9.55228 19.5523 10 19 10H18V18.5C18 19.8807 16.8807 21 15.5 21H8.5C7.11929 21 6 19.8807 6 18.5V10H5C4.44772 10 4 9.55228 4 9Z"
          fill="white"
          fillOpacity={opacity ? opacity : 0.56}
        />
      </g>
    </g>
  </svg>
);

export const BtnList: FC<IconProps> = ({ opacity, color }) => (
  <div style={{ width: '1.5rem', height: '1.5rem' }}>
    <svg width="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="&#226;&#156;&#143;&#239;&#184;&#143; Icon">
        <path
          id="Subtract"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.41439 6.3547C1 7.05896 1 7.91974 1 9.6413V16.9998C1 19.3568 1 20.5353 1.73223 21.2675C2.46447 21.9998 3.64298 21.9998 6 21.9998H18C20.357 21.9998 21.5355 21.9998 22.2678 21.2675C23 20.5353 23 19.3568 23 16.9998V9.6413C23 7.91974 23 7.05896 22.5856 6.3547C22.1712 5.65044 21.4188 5.23241 19.9139 4.39635L14.9139 1.61857C13.4895 0.827285 12.7774 0.431641 12 0.431641C11.2226 0.431641 10.5105 0.827285 9.08614 1.61857L4.08614 4.39635C2.58123 5.23241 1.82877 5.65044 1.41439 6.3547ZM9.5 10.4998C9.5 11.3282 8.82843 11.9998 8 11.9998C7.17157 11.9998 6.5 11.3282 6.5 10.4998C6.5 9.67134 7.17157 8.99976 8 8.99976C8.82843 8.99976 9.5 9.67134 9.5 10.4998ZM16 11.9998C16.8284 11.9998 17.5 11.3282 17.5 10.4998C17.5 9.67134 16.8284 8.99976 16 8.99976C15.1716 8.99976 14.5 9.67134 14.5 10.4998C14.5 11.3282 15.1716 11.9998 16 11.9998ZM9.79029 18.0234C9.20424 17.3983 8.875 16.5505 8.875 15.6664V14.9998C8.875 14.4475 9.32271 13.9998 9.875 13.9998H14.125C14.6773 13.9998 15.125 14.4475 15.125 14.9998V15.6664C15.125 16.5505 14.7958 17.3983 14.2097 18.0234C13.6237 18.6486 12.8288 18.9998 12 18.9998C11.1712 18.9998 10.3763 18.6486 9.79029 18.0234Z"
          fill={color}
          fillOpacity={opacity}
        />
      </g>
    </svg>
  </div>
);

export const BtnGen: FC<IconProps> = ({ opacity, color }) => (
  <div style={{ width: '1.5rem', height: '1.5rem' }}>
    <svg width="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="&#226;&#156;&#143;&#239;&#184;&#143; Icon">
        <path
          id="Vector"
          d="M22.4 10.0916L21.0961 9.81547L20.4442 8.57273L19.7923 9.81547L18.4884 10.0916L19.3848 11.1134L19.249 12.5219L20.4442 11.9143L21.6394 12.5219L21.4764 11.1134L22.4 10.0916ZM16.9129 7.27475L18.5156 6.47388L20.1182 7.27475L19.9009 5.39683L21.0961 4.01601L19.3577 3.65699L18.5156 2L17.6735 3.65699L15.935 4.01601L17.1302 5.39683L16.9129 7.27475ZM8.8996 5.94916L10.0948 5.3416L11.29 5.94916L11.127 4.54072L12.0234 3.51891L10.7196 3.24274L10.0676 2L9.44288 3.24274L8.13901 3.51891L9.03542 4.54072L8.8996 5.94916ZM16.3153 9.12506L15.3917 8.1861C14.6312 7.41284 13.4088 7.41284 12.6482 8.1861L2.57044 18.4042C1.80985 19.1775 1.80985 20.4202 2.57044 21.1935L3.49401 22.1324C4.25459 22.9057 5.47696 22.9057 6.23755 22.1324L16.3153 11.8867C17.0759 11.1411 17.0759 9.87071 16.3153 9.12506C17.0759 9.87071 16.3153 9.12506 16.3153 9.12506ZM15.3917 10.9754L12.6482 13.7646C12.4037 14.0132 11.9963 14.0132 11.7246 13.7646L10.8282 12.8257C10.5838 12.5771 10.5838 12.1629 10.8282 11.8867L13.5718 9.09744C13.8162 8.8489 14.2237 8.8489 14.4953 9.09744L15.4189 10.0364C15.6362 10.285 15.6362 10.7268 15.3917 10.9754C15.6362 10.7268 15.3917 10.9754 15.3917 10.9754ZM17.6463 15.974L17.2117 15.1455L16.7771 15.974L15.9079 16.1397L16.5055 16.8301L16.3968 17.769L17.1846 17.3548L17.9723 17.769L17.8908 16.8577L18.4884 16.1673L17.6463 15.974Z"
          fill={color}
          fillOpacity={opacity}
        />
      </g>
    </svg>
  </div>
);

export const BtnLeft: FC<IconProps> = ({ opacity }) => (
  <div style={{ width: '1.5rem', height: '1.5rem' }}>
    <svg width="100%" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.9438 11.186C15.9438 14.498 13.769 16.6812 10.0586 16.6812H8.06641C7.52686 16.6812 7.14502 16.2744 7.14502 15.7764C7.14502 15.27 7.51855 14.8633 8.06641 14.8633H10.0005C12.6733 14.8633 14.1426 13.2778 14.1426 11.1113C14.1426 8.95312 12.6733 7.43408 10.0005 7.43408H4.71289L2.78711 7.35107L4.24805 8.57959L6.38965 10.6631C6.54736 10.8291 6.65527 11.0283 6.65527 11.2939C6.65527 11.8086 6.29834 12.1821 5.75879 12.1821C5.53467 12.1821 5.28564 12.0742 5.10303 11.8999L0.338379 7.20166C0.147461 7.01904 0.0478516 6.77832 0.0478516 6.5293C0.0478516 6.28027 0.147461 6.03125 0.338379 5.85693L5.10303 1.15869C5.28564 0.984375 5.53467 0.876465 5.75879 0.876465C6.29834 0.876465 6.65527 1.25 6.65527 1.75635C6.65527 2.03027 6.54736 2.22949 6.38965 2.38721L4.24805 4.4707L2.78711 5.70752L4.71289 5.62451H9.94238C13.7192 5.62451 15.9438 7.89062 15.9438 11.186Z"
        fill="#FFFFFF"
        fillOpacity={opacity ? opacity : 0.56}
      />
    </svg>
  </div>
);

export const BtnRefresh: FC<IconProps> = ({ opacity }) => (
  <div style={{ width: '1.5rem', height: '1.5rem' }}>
    <svg width="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="R / ic_24_refresh_outline">
        <path
          id="Union"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 3C7.02944 3 3 7.02944 3 12C3 12.5523 2.55228 13 2 13C1.44772 13 1 12.5523 1 12C1 5.92487 5.92487 1 12 1C15.1528 1 17.9953 2.32695 20 4.45025V3C20 2.44772 20.4477 2 21 2C21.5523 2 22 2.44772 22 3V7C22 7.55228 21.5523 8 21 8H17C16.4477 8 16 7.55228 16 7C16 6.44772 16.4477 6 17 6H18.7084C17.0595 4.15751 14.6647 3 12 3ZM21.9998 11C22.5521 11 22.9998 11.4477 22.9998 12C22.9998 18.0751 18.075 23 11.9998 23C8.23277 23 4.9094 21.1063 2.92754 18.222C2.97125 18.4917 3 18.7602 3 19V20C3 20.5523 2.55228 21 2 21C1.44772 21 1 20.5523 1 20V16C1 15.4477 1.44772 15 2 15H6C6.55228 15 7 15.4477 7 16C7 16.5523 6.55228 17 6 17H5C4.84083 17 4.66899 16.9873 4.49254 16.966C6.10492 19.3985 8.86605 21 11.9998 21C16.9704 21 20.9998 16.9706 20.9998 12C20.9998 11.4477 21.4475 11 21.9998 11Z"
          fill="white"
          fillOpacity={opacity ? opacity : 0.56}
        />
      </g>
    </svg>
  </div>
);

export const BtnRight: FC<IconProps> = ({ opacity }) => (
  <div style={{ width: '1.5rem', height: '1.5rem', transform: 'scaleX(-1)', flex: '0 0 1.5rem' }}>
    <svg width="100%" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.9438 11.186C15.9438 14.498 13.769 16.6812 10.0586 16.6812H8.06641C7.52686 16.6812 7.14502 16.2744 7.14502 15.7764C7.14502 15.27 7.51855 14.8633 8.06641 14.8633H10.0005C12.6733 14.8633 14.1426 13.2778 14.1426 11.1113C14.1426 8.95312 12.6733 7.43408 10.0005 7.43408H4.71289L2.78711 7.35107L4.24805 8.57959L6.38965 10.6631C6.54736 10.8291 6.65527 11.0283 6.65527 11.2939C6.65527 11.8086 6.29834 12.1821 5.75879 12.1821C5.53467 12.1821 5.28564 12.0742 5.10303 11.8999L0.338379 7.20166C0.147461 7.01904 0.0478516 6.77832 0.0478516 6.5293C0.0478516 6.28027 0.147461 6.03125 0.338379 5.85693L5.10303 1.15869C5.28564 0.984375 5.53467 0.876465 5.75879 0.876465C6.29834 0.876465 6.65527 1.25 6.65527 1.75635C6.65527 2.03027 6.54736 2.22949 6.38965 2.38721L4.24805 4.4707L2.78711 5.70752L4.71289 5.62451H9.94238C13.7192 5.62451 15.9438 7.89062 15.9438 11.186Z"
        fill="#FFFFFF"
        fillOpacity={opacity ? opacity : 0.56}
      />
    </svg>
  </div>
);

export const BtnNoHeard: FC<IconProps> = ({ opacity }) => (
  <div style={{ width: '1.5rem', height: '1.5rem', transform: 'scaleX(-1)' }}>
    <svg width="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="item">
        <path
          id="Union"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.4335 16.5143L20.6146 18.2106L19.3867 19.7894L1.38672 5.78935L2.6146 4.21065L3.93987 5.24141C4.82388 4.49467 5.98419 4 7.36651 4C9.22363 4 10.8102 5.4575 12.0007 6.84211C13.1911 5.4575 14.7777 4 16.6348 4C19.9601 4 22.0007 6.86258 22.0007 9.21054C22.0007 11.7995 20.4763 14.2936 18.4335 16.5143ZM2.00066 9.21054C2.00066 9.07874 2.00709 8.94532 2.01982 8.81087L15.4965 19.2927C14.3041 20.2978 13.0924 21.2085 12.0007 22C7.86273 19 2.00066 14.2875 2.00066 9.21054Z"
          fill="white"
          fillOpacity={opacity ? opacity : 0.56}
        />
      </g>
    </svg>
  </div>
);

export const BtnNoHeardMobile: FC<IconProps> = () => (
  <div style={{ width: '1.5rem', height: '1.5rem', transform: 'scaleX(-1)', flex: '0 0 1.5rem' }}>
    <svg viewBox="-2 -3 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.36585 0C2.04057 0 0 2.86258 0 5.21054C0 10.2875 5.86207 15 10 18C14.1379 15 20 10.2875 20 5.21054C20 2.86258 17.9594 0 14.6342 0C12.777 0 11.1905 1.4575 10 2.84211C8.80953 1.4575 7.22297 0 5.36585 0Z"
        fill="#FF4D5E"
      />
    </svg>
  </div>
);

export const BtnNoHeardAction: FC<IconProps> = () => (
  <div style={{ width: '1.5rem', height: '1.5rem', transform: 'scaleX(-1)', flex: '0 0 1.5rem' }}>
    <svg viewBox="-2 -3 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.24174 3.49406L10 4.37598L10.7583 3.49406C11.3296 2.8295 11.9595 2.19178 12.6357 1.72445C13.3101 1.25831 13.9746 1 14.6342 1C17.3278 1 19 3.33198 19 5.21054C19 7.37236 17.74 9.58392 15.8108 11.7126C14.0799 13.6223 11.9221 15.3441 10 16.7614C8.07793 15.3441 5.92006 13.6223 4.18924 11.7126C2.26004 9.58392 1 7.37236 1 5.21054C1 3.33198 2.67225 1 5.36585 1C6.02538 1 6.68992 1.25831 7.36434 1.72445C8.04047 2.19178 8.67036 2.8295 9.24174 3.49406Z"
        stroke="white"
        strokeOpacity="0.96"
        strokeWidth="2"
      />
    </svg>
  </div>
);

export const BtnCopyMobile: FC<IconProps> = () => (
  <div style={{ width: '1.5rem', height: '1.5rem', transform: 'scaleX(-1)', flex: '0 0 1.5rem' }}>
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 4C7 3.44772 7.44772 3 8 3H20C20.5523 3 21 3.44772 21 4V16C21 16.5523 20.5523 17 20 17H19V7C19 5.89543 18.1046 5 17 5H7V4ZM4 7C3.44772 7 3 7.44772 3 8V20C3 20.5523 3.44772 21 4 21H16C16.5523 21 17 20.5523 17 20V8C17 7.44772 16.5523 7 16 7H4Z"
        fill="white"
        fillOpacity="0.56"
      />
    </svg>
  </div>
);

export const BtnCopy = () => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.1715 10.2411C20.6358 9.77676 21.187 9.40846 21.7936 9.15719C22.4002 8.90592 23.0504 8.77659 23.707 8.77659C24.3636 8.77659 25.0138 8.90592 25.6204 9.15719C26.2271 9.40846 26.7783 9.77676 27.2426 10.2411C27.7069 10.7053 28.0752 11.2565 28.3264 11.8632C28.5777 12.4698 28.707 13.12 28.707 13.7766C28.707 14.4332 28.5777 15.0834 28.3264 15.69C28.0752 16.2966 27.7069 16.8478 27.2426 17.3121"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.4144 20.1418C23.9501 20.6061 23.399 20.9743 22.7923 21.2256C22.1857 21.4769 21.5355 21.6062 20.8789 21.6062C20.2223 21.6062 19.5721 21.4769 18.9655 21.2256C18.3589 20.9743 17.8077 20.6061 17.3434 20.1418"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M27.2427 17.3125L24.4142 20.1409" stroke="white" strokeWidth="2" />
    <line
      x1="17.3431"
      y1="20.1406"
      x2="16.636"
      y2="19.4335"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <line
      x1="20.1714"
      y1="10.2423"
      x2="18.7572"
      y2="11.6566"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M16.6361 26.5089C16.1718 26.9732 15.6206 27.3415 15.014 27.5928C14.4074 27.8441 13.7572 27.9734 13.1006 27.9734C12.444 27.9734 11.7938 27.8441 11.1872 27.5928C10.5805 27.3415 10.0293 26.9732 9.56505 26.5089C9.10076 26.0447 8.73246 25.4935 8.48119 24.8868C8.22991 24.2802 8.10059 23.63 8.10059 22.9734C8.10059 22.3168 8.22991 21.6666 8.48119 21.06C8.73246 20.4534 9.10076 19.9022 9.56505 19.4379"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.3932 16.6082C12.8575 16.1439 13.4087 15.7757 14.0153 15.5244C14.6219 15.2731 15.2721 15.1438 15.9287 15.1438C16.5853 15.1438 17.2355 15.2731 17.8421 15.5244C18.4488 15.7757 19 16.1439 19.4642 16.6082"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M9.56494 19.4375L12.3934 16.6091" stroke="white" strokeWidth="2" />
    <line
      x1="19.4645"
      y1="16.6094"
      x2="20.1716"
      y2="17.3165"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <line
      x1="16.6362"
      y1="26.5077"
      x2="18.0504"
      y2="25.0934"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export const IconTextWrapper = styled.div`
  padding: 10px;
  background: rgba(255, 255, 255, 0.12);
  border-radius: 14px;
  line-height: 1;
  align-items: center;
  display: inline-flex;
  margin: 0 8px 12px 0;
  cursor: pointer;
`;
