import { useEffect, useState } from 'react';
import { capitalFirstLetter } from '@/utils/string';
import HistorySheet from '@/components/generate/HistorySheet';
import './history-sheet.css';
import { IconTextWrapper } from '@/components/Icons';
import { useGenerateStore } from '@/store/generate';

const hintsBase = [
  'V-Ray',
  'HD',
  'Детализация',
  'студийное освещение',
  'профессиональный',
  'яркие цвета',
  'высокое разрешение',
  'контраст',
  'натуральные цвета',
  'сепия',
  'черно-белое',
  'минимализм',
  'реалистичность',
  'макро',
  'металлик',
  'вектор',
  'комикс'
];

const PromptAssistant = () => {
  const { setPrompt } = useGenerateStore((state) => ({
    setPrompt: state.setPrompt
  }));

  return (
    <>
      <div className="scroll-container">
        <div className="scroll-container--content">
          <div className="scroll-container--content__inner">
            <HistorySheet changePrompt={setPrompt} />
            <PromptHints />
          </div>
        </div>
      </div>
    </>
  );
};

const PromptHints = () => {
  const [hints, setHints] = useState<string[]>([]);

  const { setPrompt, prompt, addSuggestion, setIsGenerating } = useGenerateStore((state) => ({
    setPrompt: state.setPrompt,
    prompt: state.prompt,
    addSuggestion: state.addSuggestion,
    setIsGenerating: state.setIsGenerating
  }));

  useEffect(() => {
    const newHints = getHints(prompt);
    setHints(newHints);
  }, [prompt]);

  const getHints = (promptCurrent: string) => {
    const hints = [...hintsBase];

    const promptWords = promptCurrent.split(' ');

    const updatedHints = hints.filter((hint) => !promptWords.includes(hint));

    return updatedHints.sort(() => Math.random() - 0.5);
  };

  const handleClick = (word: string) => {
    const newPrompt = `${prompt} ${word}`.trim();
    setPrompt(newPrompt);
    addSuggestion(word);
    setIsGenerating(false);
  };

  return (
    <>
      {hints.map((hint) => (
        <IconTextWrapper
          key={hint}
          onClick={() => handleClick(hint)}
          style={!prompt ? { padding: '14px' } : {}}
        >
          {prompt && '+'}&nbsp;
          {capitalFirstLetter(hint)}
        </IconTextWrapper>
      ))}
    </>
  );
};

export default PromptAssistant;
