import { useEffect, useState } from 'react';
import {
  useCreatePostcardMutation,
  usePostcardStatusChangedSubscription
} from '@/graphql/graphQlApiHooks';
import { useGenerateStore } from '@/store/generate';
import { CENSOR } from '@/utils/regexp';

export type StylesImg =
  | 'no_style'
  | 'christmas'
  | 'portrait'
  | 'professional_studio'
  | 'high_quality_art'
  | '3d_render'
  | 'soviet_cartoon'
  | 'cartoon'
  | 'pencil_drawing'
  | 'mosaic'
  | 'christian_icon'
  | 'oil_painting'
  | 'renaissance'
  | 'classicism'
  | 'khokhloma'
  | 'picasso'
  | 'malevich'
  | 'gonharova'
  | 'aivazovsky'
  | 'kandinsky';

const useImageManager = () => {
  const [newPostCardId, setNewPostCardId] = useState<string | null>(null);
  const [lastGenerateCall, setLastGenerateCall] = useState<number>(0);

  const {
    postCards,
    setPostCards,
    updateCardInPostCards,
    currentPostCardIndex,
    setCurrentCardIndex
  } = useGenerateStore((state) => ({
    postCards: state.postCards,
    setPostCards: state.setPostCards,
    updateCardInPostCards: state.updateCardInPostCards,
    deleteCardInPostCards: state.deleteCardInPostCards,
    setCurrentCardIndex: state.setCurrentCardIndex,
    currentPostCardIndex: state.currentPostCardIndex
  }));

  const [postcard_Create] = useCreatePostcardMutation();

  const { data: subscriptionData, error: subscriptionError } = usePostcardStatusChangedSubscription(
    {
      variables: { id: newPostCardId ?? '' }
    }
  );

  const generate = async () => {
    const { prompt, imgStyle, setPrompt, suggestions, setIsGenerating, isGenerating } =
      useGenerateStore.getState();
    const now = Date.now();
    if (now - lastGenerateCall < 2000) return;
    setLastGenerateCall(now);

    const sanitizedInput = prompt.replace(new RegExp(CENSOR.source, 'gi'), '');
    setPrompt(sanitizedInput);

    const suggestionsToUse = isGenerating ? [] : suggestions;
    if (!isGenerating) {
      setIsGenerating(true);
    }

    const { data } = await postcard_Create({
      variables: {
        input: {
          prompt: sanitizedInput,
          promptOriginal: prompt,
          style: imgStyle.styleName,
          suggestions: suggestionsToUse
        }
      }
    });
    setCurrentCardIndex(postCards.length);
    if (data?.createPostcard) {
      setNewPostCardId(data.createPostcard.id);
      setCurrentCardIndex(postCards.length);
      setPostCards(data.createPostcard);
    }

    return data?.createPostcard.id;
  };

  useEffect(() => {
    if (subscriptionData?.postcardStatusChanged && newPostCardId) {
      updateCardInPostCards(subscriptionData.postcardStatusChanged);
    }
  }, [subscriptionData, subscriptionError, newPostCardId]);

  useEffect(() => {
    if (!postCards.length) return;
    const { setPrompt } = useGenerateStore.getState();
    setPrompt(postCards[currentPostCardIndex]?.prompt ?? '');
  }, [currentPostCardIndex]);

  return {
    generate
  };
};

export default useImageManager;
